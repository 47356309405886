define('ui/controllers/users/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    // role: 'viewer',
    roles: ['super_admin', 'admin', 'technician', 'viewer'],
    actions: {
      // selectRole(role) {
      //   this.set('role', role);
      // },

      saveUser: function saveUser(model) {
        var self = this;
        this.store.findRecord('user', model.id).then(function (user) {
          user.save().then(function () {
            self.transitionToRoute('users');
          }).catch(function () {});
        });
      }
    }
  });
});