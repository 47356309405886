define('ui/components/submit-link', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    tagName: '',
    company_id: 1,
    user_id: 1,

    didRender: function didRender() {
      var cid = this.get('survey.data.company_id');
      if (cid == null) {
        this.set('company_id', null);
      } else {
        this.set('company_id', cid);
      }

      var uid = this.get('currentUser.user.id');
      if (uid == null || uid == undefined) {
        this.set('user_id', 1);
      } else {
        this.set('user_id', uid);
      }
    }
  });
});