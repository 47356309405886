define('ui/controllers/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    filteredCompanies: computed('model.user', 'model.user.companies', function () {
      var assignedCompanyIds = this.get('model.user.companies').map(function (company) {
        return company.id;
      });

      var filteredCompanies = this.get('model.allCompanies').filter(function (company) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = assignedCompanyIds[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var companyId = _step.value;

            if (company.id == companyId) return false;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return true;
      }).sort(function (a, b) {
        var nameA = a.get('name').toUpperCase();
        var nameB = b.get('name').toUpperCase();

        if (nameA < nameB) return -1;
        if (name > nameB) return 1;
        return 0;
      });

      return filteredCompanies;
    }),

    filteredSurveys: computed('model.user', 'model.user.surveys', 'model.user.companies', function () {
      var assignedSurveyIds = this.get('model.user.surveys').map(function (survey) {
        return survey.id;
      });
      var assignedCompanyIds = this.get('model.user.companies').map(function (company) {
        return company.id;
      });

      var filteredSurveys = this.get('model.allSurveys').filter(function (survey) {
        var _iteratorNormalCompletion2 = true;
        var _didIteratorError2 = false;
        var _iteratorError2 = undefined;

        try {
          for (var _iterator2 = assignedCompanyIds[Symbol.iterator](), _step2; !(_iteratorNormalCompletion2 = (_step2 = _iterator2.next()).done); _iteratorNormalCompletion2 = true) {
            var companyId = _step2.value;

            if (survey.get('company_id') == companyId) return true;
          }
        } catch (err) {
          _didIteratorError2 = true;
          _iteratorError2 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion2 && _iterator2.return) {
              _iterator2.return();
            }
          } finally {
            if (_didIteratorError2) {
              throw _iteratorError2;
            }
          }
        }

        return false;
      }).filter(function (survey) {
        var _iteratorNormalCompletion3 = true;
        var _didIteratorError3 = false;
        var _iteratorError3 = undefined;

        try {
          for (var _iterator3 = assignedSurveyIds[Symbol.iterator](), _step3; !(_iteratorNormalCompletion3 = (_step3 = _iterator3.next()).done); _iteratorNormalCompletion3 = true) {
            var surveyId = _step3.value;

            if (survey.id == surveyId) return false;
          }
        } catch (err) {
          _didIteratorError3 = true;
          _iteratorError3 = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion3 && _iterator3.return) {
              _iterator3.return();
            }
          } finally {
            if (_didIteratorError3) {
              throw _iteratorError3;
            }
          }
        }

        return true;
      }).sort(function (a, b) {
        var nameA = a.get('name').toUpperCase();
        var nameB = b.get('name').toUpperCase();

        if (nameA < nameB) return -1;
        if (name > nameB) return 1;
        return 0;
      });

      return filteredSurveys;
    }),

    actions: {
      refreshRoute: function refreshRoute() {
        this.send('refreshCurrentRoute');
      }
    }
  });
});