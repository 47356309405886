define('ui/routes/mobile-form', ['exports', 'ember-simple-auth/mixins/unauthenticated-route-mixin'], function (exports, _unauthenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend(_unauthenticatedRouteMixin.default, {
    model: function model(params) {
      return RSVP.hash({
        survey: this.get('store').findRecord('survey', params.survey_id),
        fields: this.get('store').query('field', { survey_id: params.survey_id }),
        choices: this.get('store').query('choice', { survey_id: params.survey_id }),
        user: this.get('store').findRecord('user', params.user_id),
        company: this.get('store').findRecord('company', params.company_id)
      });
    }
  });
});