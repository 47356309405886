define('ui/routes/surveys/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model(params) {
      return RSVP.hash({
        survey: this.get('store').findRecord('survey', params.survey_id),
        companies: this.get('store').findAll('company')
      });
    }
  });
});