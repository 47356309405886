define('ui/routes/diagnostics/show/rules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model() {
      var diagnostic = this.modelFor('diagnostics.show');

      return RSVP.hash({
        diagnostic: diagnostic,
        all_rules: this.get('store').findAll('rule'),
        diagnostic_rules: this.get('store').query('rule', { diagnostic_id: diagnostic.id })
      });
    }
  });
});