define('ui/models/answer', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = _emberData.default.Model.extend({
    text: _emberData.default.attr('string'),
    integer: _emberData.default.attr('string'),
    decimal: _emberData.default.attr('string'),
    image: _emberData.default.attr('string'),
    image_original: _emberData.default.attr('string'),
    geopoint: _emberData.default.attr('object'),
    choices: _emberData.default.attr('string'),
    field_name: _emberData.default.attr('string'),
    field_id: _emberData.default.attr('string'),
    repeat_number: _emberData.default.attr('string'),
    timestamp: _emberData.default.attr('string'),

    isText: computed('text', function () {
      var isText = get(this, 'text') !== null;
      return isText;
    }),
    isInteger: computed('integer', function () {
      var isInteger = get(this, 'integer') !== null && get(this, 'decimal') == null;
      return isInteger;
    }),
    isDecimal: computed('decimal', function () {
      var isDecimal = get(this, 'decimal') !== null;
      return isDecimal;
    }),
    isTimestamp: computed('timestamp', function () {
      var isTimestamp = get(this, 'timestamp') !== null;
      return isTimestamp;
    }),
    isImage: computed('image', function () {
      var isImage = get(this, 'image') !== null;
      return isImage;
    }),
    isGeopoint: computed('geopoint', function () {
      var geopoint = get(this, 'geopoint');
      var isLat = geopoint.lat !== undefined;
      var isLong = geopoint.long !== undefined;

      return isLat && isLong;
    }),
    isChoices: computed('choices', function () {
      var isChoices = get(this, 'choices') !== null;
      return isChoices;
    }),
    isRN0: computed('repeat_number', function () {
      var rn = get(this, 'repeat_number');
      return rn == 0;
    })
  });
});