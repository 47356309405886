define('ui/components/geo-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['geo-field', 'field'],
    attributeBindings: ['data-id', 'data-type', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message'],

    didInsertElement: function didInsertElement() {

      var latitude = 39.2779;
      var longitude = -99.1701;

      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
          latitude = position.coords.latitude;
          longitude = position.coords.longitude;

          renderMap(latitude, longitude);
        });

        renderMap(latitude, longitude);
      } else {
        renderMap(latitude, longitude);
      }
    },
    didRender: function didRender() {}
  });


  function renderMap(latitude, longitude) {
    var latlng = new google.maps.LatLng(latitude, longitude);
    var map = new google.maps.Map(document.getElementById('geo-map'), {
      center: latlng,
      zoom: 4,
      mapTypeId: google.maps.MapTypeId.ROADMAP
    });
    var marker = new google.maps.Marker({
      position: latlng,
      map: map,
      title: 'Set lat/lon values for this property',
      draggable: true
    });
    google.maps.event.addListener(marker, 'dragend', function (a) {
      var lat = document.getElementById('latitude');
      lat.value = a.latLng.lat().toFixed(6);
      var long = document.getElementById('longitude');
      long.value = a.latLng.lng().toFixed(6);
    });
    var lat = document.getElementById('latitude');
    lat.value = latitude.toFixed(6);
    var long = document.getElementById('longitude');
    long.value = longitude.toFixed(6);
  }
});