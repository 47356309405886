define('ui/controllers/users/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    role: 'viewer',
    roles: ['super_admin', 'admin', 'technician', 'viewer'],

    actions: {
      selectRole: function selectRole(role) {
        this.set('role', role);
      },
      saveUser: function saveUser() {
        var user = this.store.createRecord('user', {
          email: this.get('model.email'),
          first_name: this.get('model.first_name'),
          last_name: this.get('model.last_name'),
          password: this.get('model.password'),
          vendor: this.get('model.vendor'),
          active: this.get('model.active'),
          role: this.get('role')
        });

        var self = this;
        user.save().then(function () {
          self.transitionToRoute('users');
          document.location.reload();
        }).catch(function () {});
      }
    }

  });
});