define('ui/components/field-filter', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    name: null,
    fields: null,
    filteredFields: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var fields = get(this, 'fields');
      set(this, 'filteredFields', fields);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);

      Ember.$('.copy').click(function () {
        var text = Ember.$(this).next();
        text.select();
        document.execCommand("Copy");
      });
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
    },


    actions: {
      filterListOfFields: function filterListOfFields() {
        var name = get(this, 'name');
        var fields = get(this, 'fields');
        // let narrowedFieldList = fields.findBy('name', name);
        var narrowedFieldList = fields.filter(function (x) {
          var str = get(x, 'name').toLowerCase().split('_').filter(String);
          return str.includes(name);
        });

        if (narrowedFieldList.length > 0) {
          set(this, 'filteredFields', narrowedFieldList);
        } else {
          set(this, 'filteredFields', fields);
        }
      }
    }

  });
});