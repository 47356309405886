define('ui/models/survey', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    xls_form_id: _emberData.default.attr('string'),
    version: _emberData.default.attr('string'),
    style: _emberData.default.attr('string'),
    phone_number: _emberData.default.attr('string'),
    note: _emberData.default.attr('string'),
    name: _emberData.default.attr('string'),
    draft: _emberData.default.attr('string'),
    integration: _emberData.default.attr('string'),
    send_reports: _emberData.default.attr('string'),
    company_id: _emberData.default.attr('string')
  });
});