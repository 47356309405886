define('ui/components/add-user-to-company', ['exports', 'ui/config/environment', 'ui/helpers/tokenParser'], function (exports, _environment, _tokenParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    selectedUsers: null,

    actions: {
      selectUser: function selectUser(user) {
        this.set('selectedUsers', user);
      },
      addUserToCompany: function addUserToCompany() {
        var self = this;

        Ember.$("#add-user-to-company .alert").removeClass("alert-danger alert-success").addClass("alert-primary").text("Processing");
        Ember.$("#add-user-to-company .submit").attr("disabled", "disabled");

        var selectedUserIds = this.selectedUsers.map(function (user) {
          return user.id;
        }).join();

        var formData = new FormData();
        formData.append("company_id", Ember.$("#add-user-to-company input[name='company_id']").val());
        formData.append("user_ids", selectedUserIds);

        Ember.$.ajax({
          url: '' + _environment.default.host + _environment.default.add_user_to_company,
          headers: {
            'Authorization': (0, _tokenParser.default)()
          },
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false
        }).done(function (data) {
          if (data.error) {
            Ember.$("#add-user-to-company .alert").removeClass("alert-primary alert-success").addClass("alert-danger").text(data.error);
            Ember.$("#add-user-to-company .submit").removeAttr("disabled");
          } else {
            self.set('selectedUsers', null);
            self.get("refreshParentRoute")();
            Ember.$("#add-user-to-company .alert").removeClass("alert-primary alert-danger").addClass("alert-success").text(data.success);
            Ember.$("#add-user-to-company .submit").removeAttr("disabled");
          }
        }).fail(function (error) {
          var errorResponseJSON = error.responseJSON && error.responseJSON.errors;
          var errorMessage = error.statusText;
          if (errorResponseJSON) {
            for (var key in errorResponseJSON) {
              errorMessage += ', ' + key + ' ' + errorResponseJSON[key];
            }
          }
          Ember.$("#add-user-to-company .alert").removeClass("alert-primary alert-success").addClass("alert-danger").text(errorMessage);
          Ember.$("#add-user-to-company .submit").removeAttr("disabled");
        });
      }
    }
  });
});