define('ui/components/report-submissions', ['exports', 'ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    host: _environment.default.host,

    actions: {
      changeSearchBuildingId: function changeSearchBuildingId() {
        var searchBuildingId = Ember.$('#searchBuildingId').val();
        this.sendAction('updateSearchBuildingId', searchBuildingId);
      },
      generateCombinedSubmissions: function generateCombinedSubmissions() {
        this.sendAction('mergeSubmissions');
      },
      generateCombinedGallery: function generateCombinedGallery() {
        this.sendAction('mergeGallery');
      }
    }
  });
});