define('ui/routes/fields/show/rules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model() {
      var field = this.modelFor('fields.show');
      var data = field.data;
      var survey_id = data.survey_id;


      return RSVP.hash({
        field: field,
        survey_rules: this.get('store').query('rule', { survey_id: survey_id }),
        field_rules: this.get('store').query('rule', { field_id: field.id })
      });
    },


    actions: {
      saveRules: function saveRules() {
        var thing = this.modelFor(this.routeName).field;
        var self = this;
        thing.save().then(function () {
          self.transitionTo('surveys');
        }).catch(function () {});
      }
    }
  });
});