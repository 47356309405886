define('ui/controllers/rules/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    feedbackUpdate: null,
    actions: {
      saveRule: function saveRule(model) {
        this.set('feedbackUpdate', null);
        var self = this;
        this.store.findRecord('rule', model.id).then(function (rule) {
          rule.save().then(function () {
            // self.transitionToRoute('rules');
            self.set('feedbackUpdate', "Rule is updated successfuly!");
          }).catch(function () {});
        });
      }
    }
  });
});