define('ui/controllers/reset-password', ['exports', 'ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      sendResetPasswordLink: function sendResetPasswordLink() {
        var email = this.get('email');

        if (email == '') {
          Ember.$('#userResetPasswordEmailForm').removeClass('d-none');
          Ember.$('#userResetPasswordEmailFormResponse .alert').removeClass('alert-success d-none').addClass('alert-danger').text("email field can not be blank");
        } else {
          Ember.$.ajax({
            url: _environment.default.host + '/api/v1/reset_password/' + email,
            type: 'GET'
          }).then(function (data) {
            Ember.$('#userResetPasswordEmailForm').addClass('d-none');
            Ember.$('#userResetPasswordEmailFormResponse .alert').removeClass('alert-danger d-none').addClass('alert-success').text(data.success);
          }).catch(function (data) {
            Ember.$('#userResetPasswordEmailForm').removeClass('d-none');
            Ember.$('#userResetPasswordEmailFormResponse .alert').removeClass('alert-success d-none').addClass('alert-danger').text(data.responseJSON.error);
          });
        }
      }
    }
  });
});