define('ui/components/sorted-rules', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    sortedRules: Ember.computed.sort('rules', 'sortBy'),
    sortBy: ['name']
  });
});