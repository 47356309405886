define('ui/components/calculate-field', ['exports'], function (exports) {
                    'use strict';

                    Object.defineProperty(exports, "__esModule", {
                                        value: true
                    });
                    var Component = Ember.Component;
                    exports.default = Component.extend({
                                        classNames: ['calculate-field', 'field'],
                                        attributeBindings: ['data-id', 'data-type', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message', 'data-calculation']
                    });
});