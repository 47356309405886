define('ui/routes/fields/show/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    // model(params){
    //   return this.store.findRecord('field', params.survey_id);
    // }
  });
});