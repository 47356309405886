define('ui/components/report-surveys', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      changeSurvey: function changeSurvey(survey) {
        this.sendAction('updateSelectedSurvey', survey.id);
      },
      changeSearchSurveyText: function changeSearchSurveyText() {
        var searchSurvey = Ember.$('#searchSurvey').val();
        this.sendAction('updateSearchSurveyText', searchSurvey);
      }
    }
  });
});