define('ui/components/repeat-heading', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	var get = Ember.get,
	    set = Ember.set;
	exports.default = Component.extend({
		n: 0,
		newN: null,
		didReceiveAttrs: function didReceiveAttrs() {

			var n = get(this, 'n');
			// n = n + 1
			set(this, "newN", n + "");
		}
	});
});