define('ui/components/app-version', ['exports', 'ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    ajax: Ember.inject.service(),
    cmsVersion: _environment.default.APP.version,
    apiVersion: '',

    didReceiveAttrs: function didReceiveAttrs() {
      var _this = this;

      this._super.apply(this, arguments);
      this.get('ajax').request('/api/v1/version').then(function (response) {
        _this.set('apiVersion', response.apiVersion);
      });
    }
  });
});