define('ui/components/survey-fields', ['exports', 'ui/config/environment', 'ui/helpers/tokenParser'], function (exports, _environment, _tokenParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get,
      set = Ember.set;

  var $ = Ember.$;

  exports.default = Component.extend({
    store: Ember.inject.service('store'),
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),
    classNames: ['survey-fields'],
    attributeBindings: ['data-survey-id'],
    parentFields: null,

    // ///////////////////////////////////////////////////////////////////////////////// init CALLBACK
    init: function init() {
      this._super.apply(this, arguments);
    },


    // ///////////////////////////////////////////////////////////////////////////////// didReceiveAttrs CALLBACK
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var fields = get(this, 'fields');

      var narrowedFields = fields.filterBy('isParentField', true);
      set(this, 'parentFields', narrowedFields);
      var survey_id = get(this, 'survey.id');
      var user_id = get(this, 'user.id');

      var formData = new FormData();
      formData.append("survey_id", survey_id);
      formData.append("user_id", user_id);

      $.ajax({
        url: '' + _environment.default.host + _environment.default.draft_submission + '/' + survey_id + '/' + user_id,
        headers: {
          'Authorization': (0, _tokenParser.default)()
        },
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false
      }).done(function (data) {
        if (data.error) {
          data.error;
        } else {
          // cl(data);
          $('.survey-fields').attr('data-submission-id', data.submission_id);
          $('.submission-id').val(data.submission_id);
          $('#submission_id').val(data.submission_id);
        }
      }).fail(function () {});
    },


    // ///////////////////////////////////////////////////////////////////////////////// didInsertElement CALLBACK
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
      resetForm();
      // Start
      // Replace ${username} with current user email.
      var username = this.get('user.email');
      $('.note-field > span > div > p').each(function () {
        var text = $(this).html();
        if (text.includes('${username}') == true) {
          var newText = text.replace(/\$\{username\}/g, username);
          $(this).html(newText);
        }
        var str = text.replace("**", "<strong>");
        str = str.replace("**", "</strong>");
        $(this).html(str);
      });
      // End

      // Start
      // store value of input fields in localstorage and sessionstorage for later use.
      // $('form').each(function(){
      //   $(this).formcache();
      // })
      // End

      relevantLogic();

      $('div[data-type="begin repeat"]').append(' \
    <div class="repeat-links text-center"> \
    <span class="btn btn-sm btn-primary add-repeat-section">+</span> \
    <span class="btn btn-sm btn-primary remove-repeat-section">-</span> \
    </div> \
    ');

      Ember.run.scheduleOnce('afterRender', this, function () {
        // $('div[data-repeat-id]').hide();
        // $('div[data-depend-field]').hide()
      });
    },
    // didInsertElement

    // ///////////////////////////////////////////////////////////////////////////////// didRender CALLBACK
    didRender: function didRender() {
      this._super.apply(this, arguments);

      // Start
      // Dont submit form on pressing Enter Key
      $('input').keypress(function (event) {
        if (event.keyCode === 10 || event.keyCode === 13) {
          event.preventDefault();
        }
      });
      // End

      // Start
      // submit form cache
      $('#submit-form').click(function (event) {
        event.preventDefault();
        var submitBtn = $(this);
        var alrt = $('.form-buttons .alert');
        submitBtn.attr('disabled', true);
        $('.collapsed .group-content, .collapsed .repeat-content').show();

        var requiredTxt = $('div.text-field[data-required=true]:visible').filter(function () {
          var val = $(this).find('input[type=text]').val();
          if (!val) {
            $(this).find('.error').text('This is a required field');
            $(this).addClass('empty');
          } else {
            $(this).removeClass('empty');
          }
          return !val;
        });
        var requiredNum = $('div.integer-field[data-required=true]:visible').filter(function () {
          var val = $(this).find('input[type=number]').val();
          if (!val) {
            $(this).find('.error').text('This is a required field');
            $(this).addClass('empty');
          } else {
            $(this).removeClass('empty');
          }
          return !val;
        });
        var requiredDecimal = $('div.decimal-field[data-required=true]:visible').filter(function () {
          var val = $(this).find('input[type=number]').val();
          if (!val) {
            $(this).find('.error').text('This is a required field');
            $(this).addClass('empty');
          } else {
            $(this).removeClass('empty');
          }
          return !val;
        });
        var requiredFile = $('div.image-field[data-required=true]:visible').filter(function () {
          var val = $(this).find('.image-preview img').attr('src').includes('http');
          if (!val) {
            $(this).find('.error').text('This is a required field');
            $(this).addClass('empty');
          } else {
            $(this).removeClass('empty');
          }
          return !val;
        });
        var requiredSelect = $('div.select-field[data-required=true]:visible .one-select').filter(function () {
          var val = $(this).find('select').val();
          if (!val) {
            $(this).find('.error').text('This is a required field');
            $(this).addClass('empty');
          } else {
            $(this).find('.error').text('');
            $(this).removeClass('empty');
          }
          return !val;
        });
        var requiredFinal = $('div.acknowledge-field[data-required=true]:visible').filter(function () {
          var checkbox = $(this).find('input[type=checkbox]');
          if (!checkbox.is(':checked')) {
            $(this).find('.error').text('This is a required field');
            $(this).addClass('empty');
          } else {
            $(this).find('.error').text('');
            $(this).removeClass('empty');
          }
          return !checkbox.is(':checked');
        });

        var patternMatched = $('div[data-constraint]:visible').filter(function () {
          var pattern = $(this).attr('data-constraint');
          var message = $(this).attr('data-constraint-message');
          var inputVal = $(this).find('input[type=text]').val();
          var regex = pattern.match(/,'(.*)'\)/)[1];
          regex = new RegExp(regex);
          if (regex.test(inputVal)) {
            $(this).find('input[type=text]').next().html('');
          } else {
            $(this).find('input[type=text]').next().html(message);
          }
          return !regex.test(inputVal);
        });

        if (requiredTxt.length > 0 || requiredNum.length > 0 || requiredDecimal.length > 0 || requiredFile.length > 0 || requiredFinal.length > 0 || patternMatched.length > 0 || requiredSelect.length > 0) {
          alrt.removeClass('alert-primary alert-success').addClass('alert-danger').text("Please fill out the required fields.");
          submitBtn.removeAttr('disabled');
          var first = $('.empty:first');
          $('html,body').animate({ scrollTop: first.offset().top }, 1000);
        } else {
          $('.image-field input').attr('disabled', true);
          $('.calculation').attr("disabled", false);

          var input_num = $("#survey-form input").length;
          var select_num = $("#survey-form select").length;
          var num_fields = input_num + select_num;
          // console.log(num_fields)
          var waitSec = num_fields / 15;
          alrt.removeClass('alert-danger alert-success').addClass('alert-primary').text("Processing form may take up to " + parseInt(waitSec) + " seconds");
          var form = $("#survey-form")[0];
          var formData = new FormData(form);
          $('.calculation').attr("disabled", true);

          $.ajax({
            url: '' + _environment.default.host + _environment.default.submissions,
            headers: {
              'Authorization': (0, _tokenParser.default)()
            },
            type: 'POST',
            data: formData,
            processData: false,
            contentType: false
            // timeout: 300000
          }).done(function () {
            alrt.removeClass('alert-primary alert-danger').addClass('alert-success').text("Form uploaded");
          }).fail(function () {
            submitBtn.removeAttr('disabled');
            alrt.removeClass('alert-primary alert-success').addClass('alert-danger').text("please try again");
          }).always(function () {
            $('.image-field input"]').attr('disabled', false);
          });
        }
      });
      // End


      // Start
      // Make repeat group work
      $('input[type="number"]').on("change", function () {
        var field = $(this);
        var field_id = field.closest('.integer-field').attr('data-id');
        var dataName = field.closest('.integer-field').attr('data-name');
        var num = field.val();
        $('div[data-repeat-count]').each(function () {
          var _this = this;

          var repeatCount = $(this).attr('data-repeat-count');

          if (repeatCount.includes(dataName)) {
            $(this).show();
            var first = $(this).find('.repeat-content:first');
            var rcArr = $(this).find('.repeat-content');
            $(this).attr('data-depend-upon', field_id);
            if (rcArr.length < num) {
              first.show();
              var diff = num - rcArr.length;

              var _loop = function _loop() {
                var clone = first.clone(true);
                var last = $(_this).find('.repeat-content:last');
                clone.insertAfter(last);

                var newLast = $(_this).find('.repeat-content:last');
                var lastNum = last.find('.repeat_number').text();
                var newNum1 = parseInt(lastNum) + 1;
                newLast.find('div[id]').removeAttr('id');
                newLast.find('.repeat_number').text(newNum1);
                newLast.find('.repeat_number_input').attr('value', newNum1);

                var inputs = newLast.find("input[name^='submission']"); //.attr('value', num)
                inputs.each(function () {
                  var name = $(this).attr('name');
                  var new_name = name.replace(/submission\[\d+\]/, 'submission[' + newNum1 + ']');
                  $(this).attr('name', new_name);
                });
                var selects = newLast.find("select[name^='submission']"); //.attr('value', num)
                selects.each(function () {
                  var name = $(this).attr('name');
                  var new_name = name.replace(/submission\[\d+\]/, 'submission[' + newNum1 + ']');
                  $(this).attr('name', new_name);
                });

                newLast.find('.image-preview img').attr('src', '');
              };

              for (var i = 1; i < diff + 1; i++) {
                _loop();
              }
            } else if (rcArr.length > num) {
              if (num < 1) {
                for (var x = 1; x < rcArr.length; x++) {
                  $(this).find('.repeat-content:last').remove();
                }
                $(this).find('.repeat-content:last .repeat_number_input').attr('value', 1);
                $(this).find('.repeat-links').hide();
                $(this).find('.repeat-content:last').hide();
              } else {
                var _diff = rcArr.length - num;
                for (var y = 0; y < _diff; y++) {
                  $(this).find('.repeat-content:last').remove();
                }
              }
            } else if (num == 1) {
              $(this).find('.repeat-content:last').show();
              $(this).find('.repeat-links').show();
            }
          }
        });
      });
      // End

      // Start
      // Make repeat sections collapsable
      $(".group-title").click(function () {
        var style = $(this).siblings('.group-content').attr('style');
        if (style == "" || style == undefined) {
          $(this).children('span').removeClass('arrow-down');
          $(this).children('span').addClass('arrow-up');
        } else {
          $(this).children('span').removeClass('arrow-up');
          $(this).children('span').addClass('arrow-down');
        }
        $(this).siblings('.group-content').slideToggle("fast");
        var group = $(this).closest('.group-field');
        if (group.find('.group-content:hidden')) {
          group.addClass('collapsed');
        }
      });
      // End

      // Start
      // Make repeat sections collapsable
      $(".repeat-title").click(function () {
        var style = $(this).siblings('.repeat-content').attr('style');
        if (style == "" || style == undefined) {
          $(this).children('span').removeClass('arrow-down');
          $(this).children('span').addClass('arrow-up');
        } else {
          $(this).children('span').removeClass('arrow-up');
          $(this).children('span').addClass('arrow-down');
        }
        $(this).siblings('.repeat-content').slideToggle("fast");
        var repeat = $(this).closest('.repeat-field');
        if (repeat.find('.repeat-content:hidden')) {
          repeat.addClass('collapsed');
        }
      });
      // End

      // Start
      // apply constraints and constraint messages
      $('div[data-constraint] .input').focusout(function () {
        var value = $(this).val();
        var str = $(this).closest('div[data-constraint]').attr('data-constraint');
        var title = $(this).closest('div[data-constraint]').attr('data-constraint-message');
        var regex = str.match(/,'(.*)'\)/)[1];
        regex = new RegExp(regex);
        if (regex.test(value)) {
          $(this).next().html('');
        } else {
          $(this).next().html(title);
        }
      });
      // End

      // Start
      // Make external links open in new tab or window
      $('a[href^="http://"], a[href^="https://"]').attr('target', '_blank');
      // End


      $('.add-repeat-section').click(function () {
        var repeat = $(this).closest('.repeat-field');
        var field_id = repeat.attr('data-depend-upon');
        var field = $('div[data-id="' + field_id + '"]');
        var num1 = field.find('input[type=number]').val();
        field.find('input[type=number]').val(parseInt(num1, 10) + 1);

        var first = repeat.find('.repeat-content:first');
        first.show();
        var last = repeat.find('.repeat-content:last');
        var num = last.find('.repeat_number').text();
        num = parseInt(num, 10) + 1;
        var clone = first.clone(true);

        clone.insertAfter(last);

        var newLast = repeat.find('.repeat-content:last');
        newLast.find('div[id]').removeAttr('id');
        newLast.find('.repeat_number').text(num);
        newLast.find(".repeat_number_input").attr('value', num);

        var inputs = newLast.find("input[name^='submission']"); //.attr('value', num)
        inputs.each(function () {
          var name = $(this).attr('name');
          var new_name = name.replace(/submission\[\d+\]/, 'submission[' + num + ']');
          $(this).attr('name', new_name);
        });
        var selects = newLast.find("select[name^='submission']"); //.attr('value', num)
        selects.each(function () {
          var name = $(this).attr('name');
          var new_name = name.replace(/submission\[\d+\]/, 'submission[' + num + ']');
          $(this).attr('name', new_name);
        });

        newLast.find('.image-preview img').attr('src', '');

        newLast.find('.image-field').each(function () {
          var field = $(this);
          var field_id = field.find('.field_id').val();
          var repeat_number = field.find('.repeat_number_input').val();
          var submission_id = $('.survey-fields').attr('data-submission-id');

          // Load images
          $.ajax({
            url: '' + _environment.default.host + _environment.default.draft_image_url + '/' + submission_id + '/' + field_id + '/' + repeat_number,
            headers: {
              'Authorization': (0, _tokenParser.default)()
            }
          }).done(function (data) {
            if (data.error) {
              data.error;
            } else {
              field.find('.image-preview img').attr('src', data.url);
            }
          }).fail(function () {});
        });
      });

      $('.remove-repeat-section').click(function () {
        var repeat = $(this).closest('.repeat-field');
        var last = repeat.find('.repeat-content:last');
        var num = last.find('.repeat_number').text();
        if (num != 1) {
          last.remove();
          var field_id = repeat.attr('data-depend-upon');
          var field = $('div[data-id="' + field_id + '"]');
          var num1 = field.find('input[type=number]').val();
          field.find('input[type=number]').val(parseInt(num1, 10) - 1);
        }
      });

      $(document).ready(function () {
        $('.image-field').each(function () {
          var field = $(this);

          // update submission_id in form
          field.click(function () {
            var submission_id = $('.survey-fields').attr('data-submission-id');
            $(this).find('input.submission-id').val(submission_id);
          });

          // show hide error on required fields
          field.find('input[type=file]').focusout(function () {
            var parent = $(this).closest('.image-field');
            var required = parent.attr('data-required').includes('true');
            var img = parent.find('.image-preview img').attr('src').includes('http');

            if (!img && required) {
              parent.find('.error').text('This is a required field.');
            } else {
              parent.find('.error').text('');
            }
          });

          // upload files to server
          field.find('input[type=file]').on('change', function () {
            var parent = $(this).closest('.image-field');
            parent.find('.image-preview img').attr('src', '');
            // TODO: change here to successfully submit images
            // let form = $(this).closest('form.upload-file')[0]
            // let formData = new FormData(form)
            var formData = new FormData();
            var submission_id = parent.find('.submission-id').val();
            formData.append('submission_id', submission_id);
            var repeat_num = parent.find('.repeat_number_input').val();
            formData.append('repeat_number', repeat_num);
            var field_id = parent.find('.field_id').val();
            formData.append('field_id', field_id);
            var imgFile = parent.find('input[type="file"]').get(0).files[0];
            formData.append('image', imgFile);
            var alrt = $(this).siblings('.alert');

            alrt.removeClass('alert-danger alert-success').addClass('alert-primary').text('Processing');

            $.ajax({
              url: '' + _environment.default.host + _environment.default.draft_answer,
              headers: {
                'Authorization': (0, _tokenParser.default)()
              },
              type: 'POST',
              data: formData,
              processData: false,
              contentType: false
            }).done(function (data) {
              if (data.error) {
                alrt.removeClass('alert-primary').removeClass('alert-success').addClass('alert-danger').text(data.error);
              } else {
                parent.find('.image-preview img').attr('src', data.url);
                relevantLogic();
                parent.find('.error').text('');
                alrt.removeClass('alert-primary').removeClass('alert-danger').addClass('alert-success').text(data.success);
              }
            }).fail(function () {
              alrt.removeClass('alert-primary').removeClass('alert-success').addClass('alert-danger').text("Upload failed");
            });
            // form.reset()
          });
        });
      });

      // START
      // RELEVENT logics
      $('html, body').on('change', function () {
        relevantLogic();
      });

      // END


      // START
      // update Calculation fields
      $('body').on('change', function () {
        $('div[data-calculation]').each(function () {
          var current = $(this);
          var repeat = current.closest('.repeat-content');
          var calculation = current.attr('data-calculation');
          var newCalc = calculation;
          var reg1 = /\$\{\w+\W/g;
          var veriables = calculation.match(reg1);
          for (var i = 0; i < veriables.length; i++) {
            var word = veriables[i];
            var trimmedWord = word.replace('${', '');
            trimmedWord = trimmedWord.replace('}', '');
            var field = null;
            if (repeat.length > 0) {
              field = repeat.find('div[data-name=' + trimmedWord + ']');
              if (!(field.length > 0)) {
                field = $('div[data-name=' + trimmedWord + ']');
              }
            } else {
              field = $('div[data-name=' + trimmedWord + ']');
            }

            var fieldVal = field.find('input[type=number]').val();
            // console.log(fieldVal)
            if (fieldVal) {
              newCalc = newCalc.replace(word, fieldVal);
            } else {
              newCalc = newCalc.replace(word, null);
            }
          } //for loop
          // console.log(newCalc)
          if (newCalc.includes(null)) {
            current.find('.calculation').val('');
          } else {
            var result = eval(newCalc);
            current.find('.calculation').val(result.toFixed(2));
          }
        });
      });
      // END

    }
  } //didRender


  );


  function resetForm() {
    $('.image-preview img').attr('src', '');
    var submission_id = $('.survey-fields').attr('data-submission-id');

    if (submission_id != undefined) {
      $.ajax({
        url: '' + _environment.default.host + _environment.default.delete_draft_submission + '/' + submission_id,
        headers: {
          'Authorization': (0, _tokenParser.default)()
        }
      });
    }
  }

  function relevantLogic() {
    $('div[data-relevent]').each(function () {
      var current = $(this);
      var repeat = current.closest('.repeat-content');
      var relevent = current.attr('data-relevent');
      relevent = relevent.replace(/\s=\s/g, " == ");
      relevent = relevent.replace(/\sand\s/g, " && ");
      relevent = relevent.replace(/\sor\s/g, " || ");

      if (relevent.includes('selected(')) {
        var reg0 = /\$\{\w+\W/;
        var var0 = relevent.match(reg0)[0];
        var0 = var0.replace('${', '');
        var0 = var0.replace('}', '');
        var reg01 = /,\s'.+'/;
        var var01 = relevent.match(reg01)[0];
        var01 = var01.replace(", '", '');
        var01 = var01.replace("'", '');
        var field0 = null;
        if (repeat.length > 0) {
          field0 = repeat.find('div[data-name=' + var0 + ']');
          if (!(field0.length > 0)) {
            field0 = $('div[data-name=' + var0 + ']');
          }
        } else {
          field0 = $('div[data-name=' + var0 + ']');
        }

        var checkbox = field0.find('input:checkbox[value=' + var01 + ']');
        if (checkbox.is(':checked')) {
          current.show().addClass('shown');
        } else {
          current.hide().removeClass('shown');
        }
        ////////////////////////////////
      } else {
        var reg1 = /\$\{\w+\W/g;
        var veriables = relevent.match(reg1);
        var newRelevent = relevent;
        var field = null;
        for (var i = 0; i < veriables.length; i++) {
          var word = veriables[i];
          var trimmedWord = word.replace('${', '');
          trimmedWord = trimmedWord.replace('}', '');
          if (repeat.length > 0) {
            field = repeat.find('div[data-name=' + trimmedWord + ']');
            if (!(field.length > 0)) {
              field = $('div[data-name=' + trimmedWord + ']');
            }
          } else {
            field = $('div[data-name=' + trimmedWord + ']');
          }

          var fieldClass = field.attr('class');
          if (fieldClass.includes('integer-field')) {
            var integerVal = field.find('input[type=number]').val();
            if (integerVal) {
              newRelevent = newRelevent.replace(word, integerVal);
            }{
              newRelevent = newRelevent.replace(word, 0);
            }
          } else if (fieldClass.includes('select-field')) {
            var selectVal = field.find('select').val();
            newRelevent = newRelevent.replace(word, '\'' + selectVal + '\'');
          } else if (fieldClass.includes('image-field')) {
            var imageVal = field.find('img').attr('src');
            if (imageVal == undefined || imageVal == '') {
              newRelevent = newRelevent.replace(word, '\'\'');
            } else {
              newRelevent = newRelevent.replace(word, '\'' + imageVal + '\'');
            }
          } else if (fieldClass.includes('text-field')) {
            var textVal = field.find('input[type=text]').val();
            newRelevent = newRelevent.replace(word, '\'' + textVal + '\'');
          }
        } // for loop

        if (eval(newRelevent)) {
          current.show().addClass('shown');
        } else {
          current.hide().removeClass('shown');
        }
      }
    });
  }
});