define('ui/components/add-survey-to-user', ['exports', 'ui/config/environment', 'ui/helpers/tokenParser'], function (exports, _environment, _tokenParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    selectedSurveys: null,

    actions: {
      selectSurvey: function selectSurvey(survey) {
        this.set('selectedSurveys', survey);
      },
      addSurveyToUser: function addSurveyToUser() {
        var self = this;

        Ember.$("#add-survey-to-user .alert").removeClass("alert-danger alert-success").addClass("alert-primary").text("Processing");
        Ember.$("#add-survey-to-user .submit").attr("disabled", "disabled");

        var selectedSurveyIds = this.selectedSurveys.map(function (survey) {
          return survey.id;
        }).join();

        var formData = new FormData();
        formData.append("user_id", Ember.$("#add-survey-to-user input[name='user_id']").val());
        formData.append("survey_ids", selectedSurveyIds);

        Ember.$.ajax({
          url: '' + _environment.default.host + _environment.default.add_survey_to_user,
          headers: {
            'Authorization': (0, _tokenParser.default)()
          },
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false
        }).done(function (data) {
          if (data.error) {
            Ember.$("#add-survey-to-user .alert").removeClass("alert-primary alert-success").addClass("alert-danger").text(data.error);
            Ember.$("#add-survey-to-user .submit").removeAttr("disabled");
          } else {
            self.set('selectedSurveys', null);
            self.get("refreshParentRoute")();
            Ember.$("#add-survey-to-user .alert").removeClass("alert-primary alert-danger").addClass("alert-success").text(data.success);
            Ember.$("#add-survey-to-user .submit").removeAttr("disabled");
          }
        }).fail(function (error) {
          var errorResponseJSON = error.responseJSON && error.responseJSON.errors;
          var errorMessage = error.statusText;
          if (errorResponseJSON) {
            for (var key in errorResponseJSON) {
              errorMessage += ', ' + key + ' ' + errorResponseJSON[key];
            }
          }
          Ember.$("#add-survey-to-user .alert").removeClass("alert-primary alert-success").addClass("alert-danger").text(errorMessage);
          Ember.$("#add-survey-to-user .submit").removeAttr("disabled");
        });
      }
    }
  });
});