define('ui/components/de-assign-diagnostic', ['exports', 'ui/config/environment', 'ui/helpers/tokenParser'], function (exports, _environment, _tokenParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.$('.de-assign-diagnostic').submit(function (e) {
        e.preventDefault();
        Ember.$('.de-assign-diagnostic .submit').attr("disabled", "disabled");

        Ember.$.ajax({
          url: '' + _environment.default.host + _environment.default.de_assign_diagnostic,
          headers: {
            'Authorization': (0, _tokenParser.default)()
          },
          type: 'POST',
          data: new FormData(this),
          processData: false,
          contentType: false
        }).done(function (data) {
          if (data.error) {
            Ember.$('.de-assign-diagnostic .submit').removeAttr("disabled");
          } else {
            Ember.$('.de-assign-diagnostic .submit').removeAttr("disabled");

            document.location.reload();
          }
        }).fail(function () {
          Ember.$('.de-assign-diagnostic .submit').removeAttr("disabled");
        });
      });
    }
  });
});