define('ui/routes/surveys/show/diagnostics', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model() {
      var survey = this.modelFor('surveys.show');

      return RSVP.hash({
        survey: survey,
        all_diagnostics: this.get('store').findAll('diagnostic'),
        survey_diagnostics: this.get('store').query('diagnostic', { survey_id: survey.id })
      });
    }
  });
});