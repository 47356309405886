define('ui/components/group-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: ['group-field'],
    attributeBindings: ['data-id', 'data-type', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message'],

    subFields: null,

    init: function init() {
      this._super.apply(this, arguments);
    },
    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var field = get(this, 'field');
      var fields = get(this, 'fields');

      var narrowedFields = fields.filterBy('group_id', field.id);
      set(this, 'subFields', narrowedFields);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
    }
  });
});