define('ui/routes/reports', ['exports', 'ember-data', 'ui/config/environment'], function (exports, _emberData, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    searchBy: "companies",
    companyId: "",
    surveyId: "",
    submissionId: "",
    searchText: "",
    searchCompanyText: "",
    searchSurveyText: "",
    searchBuildingId: "",

    model: function model() {
      var _this = this;

      var companies = [];
      var surveys = [];
      var submissions = [];
      var showCompanies = true;
      var showSurveys = true;
      var showSubmissions = true;

      if (this.searchBy == "companies") {
        showCompanies = true;

        if (this.searchCompanyText) {
          companies = _emberData.default.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              _this.get('store').findAll('company').then(function (companies) {
                var filteredCompanies = companies.filter(function (company) {
                  var name = company.get('name').toUpperCase();
                  var matchText = _this.searchCompanyText.toUpperCase();
                  return name.indexOf(matchText) > -1;
                }).sort(function (a, b) {
                  return a.get('id') - b.get('id');
                });
                resolve(filteredCompanies);
              });
            })
          });
        } else {
          companies = _emberData.default.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              _this.get('store').findAll('company').then(function (companies) {
                var filteredCompanies = companies.filter(function () {
                  return true;
                }).sort(function (a, b) {
                  return a.get('id') - b.get('id');
                });
                resolve(filteredCompanies);
              });
            })
          });
        }

        if (this.companyId) {
          showSurveys = true;
          showSubmissions = false;

          if (this.searchSurveyText) {
            surveys = _emberData.default.PromiseArray.create({
              promise: new Ember.RSVP.Promise(function (resolve) {
                _this.get('store').findAll('survey').then(function (surveys) {
                  var filteredSurveys = surveys.filter(function (survey) {
                    var name = survey.get('name').toUpperCase();
                    var matchText = _this.searchSurveyText.toUpperCase();
                    return name.indexOf(matchText) > -1 && survey.get('company_id') == _this.companyId;
                  }).sort(function (a, b) {
                    return a.get('id') - b.get('id');
                  });
                  resolve(filteredSurveys);
                });
              })
            });
          } else {
            surveys = _emberData.default.PromiseArray.create({
              promise: new Ember.RSVP.Promise(function (resolve) {
                _this.get('store').findAll('survey').then(function (surveys) {
                  var filteredSurveys = surveys.filter(function (survey) {
                    return survey.get('company_id') == _this.companyId;
                  }).sort(function (a, b) {
                    return a.get('id') - b.get('id');
                  });
                  resolve(filteredSurveys);
                });
              })
            });
          }

          if (this.surveyId) {
            showSubmissions = true;

            if (this.searchBuildingId) {
              submissions = _emberData.default.PromiseArray.create({
                promise: new Ember.RSVP.Promise(function (resolve) {
                  _this.get('store').query('submission', { survey_id: _this.surveyId }).then(function (submissions) {
                    var filteredSubmissions = submissions.filter(function (submission) {
                      var name = submission.get('building_id').toUpperCase();
                      var matchText = _this.searchBuildingId.toUpperCase();
                      return name.indexOf(matchText) > -1;
                    }).sort(function (a, b) {
                      return a.get('id') - b.get('id');
                    });
                    resolve(filteredSubmissions);
                  });
                })
              });
            } else {
              submissions = _emberData.default.PromiseArray.create({
                promise: new Ember.RSVP.Promise(function (resolve) {
                  _this.get('store').query('submission', { survey_id: _this.surveyId }).then(function (submissions) {
                    var filteredSubmissions = submissions.filter(function () {
                      return true;
                    }).sort(function (a, b) {
                      return a.get('id') - b.get('id');
                    });
                    resolve(filteredSubmissions);
                  });
                })
              });
            }
          }
        } else {
          showSurveys = false;
          showSubmissions = false;
        }
      } else if (this.searchBy == "surveys") {
        showCompanies = false;
        showSurveys = true;
        showSubmissions = false;

        if (this.searchSurveyText) {
          surveys = _emberData.default.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              _this.get('store').findAll('survey').then(function (surveys) {
                var filteredSurveys = surveys.filter(function (survey) {
                  var name = survey.get('name').toUpperCase();
                  var matchText = _this.searchSurveyText.toUpperCase();
                  return name.indexOf(matchText) > -1;
                }).sort(function (a, b) {
                  return a.get('id') - b.get('id');
                });
                resolve(filteredSurveys);
              });
            })
          });
        } else {
          surveys = _emberData.default.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              _this.get('store').findAll('survey').then(function (surveys) {
                var filteredSurveys = surveys.filter(function () {
                  return true;
                }).sort(function (a, b) {
                  return a.get('id') - b.get('id');
                });
                resolve(filteredSurveys);
              });
            })
          });
        }

        if (this.surveyId) {
          showSubmissions = true;

          if (this.searchBuildingId) {
            submissions = _emberData.default.PromiseArray.create({
              promise: new Ember.RSVP.Promise(function (resolve) {
                _this.get('store').query('submission', { survey_id: _this.surveyId }).then(function (submissions) {
                  var filteredSubmissions = submissions.filter(function (submission) {
                    var name = submission.get('building_id').toUpperCase();
                    var matchText = _this.searchBuildingId.toUpperCase();
                    return name.indexOf(matchText) > -1 && submission.get('survey_id') == _this.surveyId;
                  }).sort(function (a, b) {
                    return a.get('id') - b.get('id');
                  });
                  resolve(filteredSubmissions);
                });
              })
            });
          } else {
            submissions = _emberData.default.PromiseArray.create({
              promise: new Ember.RSVP.Promise(function (resolve) {
                _this.get('store').query('submission', { survey_id: _this.surveyId }).then(function (submissions) {
                  var filteredSubmissions = submissions.filter(function (submission) {
                    return submission.get('survey_id') == _this.surveyId;
                  }).sort(function (a, b) {
                    return a.get('id') - b.get('id');
                  });
                  resolve(filteredSubmissions);
                });
              })
            });
          }
        }
      } else if (this.searchBy == "building id") {
        showCompanies = false;
        showSurveys = false;
        showSubmissions = true;

        if (this.searchBuildingId) {
          submissions = _emberData.default.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              _this.get('store').query('submission', { survey_id: _this.surveyId }).then(function (submissions) {
                var filteredSubmissions = submissions.filter(function (submission) {
                  var name = submission.get('building_id').toUpperCase();
                  var matchText = _this.searchBuildingId.toUpperCase();
                  return name.indexOf(matchText) > -1;
                }).sort(function (a, b) {
                  return a.get('id') - b.get('id');
                });
                resolve(filteredSubmissions);
              });
            })
          });
        } else {
          submissions = _emberData.default.PromiseArray.create({
            promise: new Ember.RSVP.Promise(function (resolve) {
              _this.get('store').query('submission', { survey_id: _this.surveyId }).then(function (submissions) {
                var filteredSubmissions = submissions.filter(function () {
                  return true;
                }).sort(function (a, b) {
                  return a.get('id') - b.get('id');
                });
                resolve(filteredSubmissions);
              });
            })
          });
        }
      }

      return {
        companies: companies,
        surveys: surveys,
        submissions: submissions,
        searchBy: this.searchBy,
        companyId: this.companyId,
        surveyId: this.surveyId,
        submissionId: this.submissionId,
        showCompanies: showCompanies,
        showSurveys: showSurveys,
        showSubmissions: showSubmissions
      };
    },


    actions: {
      updateSelectedCompany: function updateSelectedCompany(companyId) {
        this.set('companyId', companyId);
        this.set('surveyId', "");
        this.refresh();
      },
      updateSelectedSurvey: function updateSelectedSurvey(surveyId) {
        this.set('surveyId', surveyId);
        this.refresh();
      },
      updateSearchBy: function updateSearchBy(searchBy) {
        this.set('searchBy', searchBy);
        this.set('surveyId', "");
        this.set('searchText', "");
        this.refresh();
      },
      updateSearchText: function updateSearchText(searchText) {
        this.set('searchText', searchText);
        this.set('companyId', "");
        this.set('surveyId', "");
        this.refresh();
      },
      updateSearchCompanyText: function updateSearchCompanyText(searchCompanyText) {
        this.set('searchCompanyText', searchCompanyText);
        this.set('companyId', "");
        this.set('surveyId', "");
        this.refresh();
      },
      updateSearchSurveyText: function updateSearchSurveyText(searchSurveyText) {
        this.set('searchSurveyText', searchSurveyText);
        this.set('surveyId', "");
        this.refresh();
      },
      updateSearchBuildingId: function updateSearchBuildingId(searchBuildingId) {
        this.set('searchBuildingId', searchBuildingId);
        this.refresh();
      },
      mergeSubmissions: function mergeSubmissions() {
        var selectedSubmissions = [];

        Ember.$.each(Ember.$("#report-submissions-list input[name='submission-checkbox']:checked"), function () {
          selectedSubmissions.push(Ember.$(this).val());
        });

        if (selectedSubmissions.length == 0) {
          window.alert('Please select submissions!!!');
        } else {
          window.open(_environment.default.host + '/combined_rpt?submission_ids=' + selectedSubmissions, '_blank');
        }
      },
      mergeGallery: function mergeGallery() {
        var selectedSubmissions = [];

        Ember.$.each(Ember.$("#report-submissions-list input[name='submission-checkbox']:checked"), function () {
          selectedSubmissions.push(Ember.$(this).val());
        });

        if (selectedSubmissions.length == 0) {
          window.alert('Please select submissions!!!');
        } else {
          window.open(_environment.default.host + '/combined_gal?submission_ids=' + selectedSubmissions, '_blank');
        }
      }
    }
  });
});