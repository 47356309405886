define('ui/routes/submission/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model(params) {
      // return this.store.findRecord('submission', params.submission_id);
      return RSVP.hash({
        submission: this.get('store').findRecord('submission', params.submission_id),
        answers: this.get('store').query('answer', { submission_id: params.submission_id })
      });
    }
  });
});