define('ui/helpers/tokenParser', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function getToken() {
    var token = void 0;

    try {
      var rawSession = localStorage.getItem('ember_simple_auth-session');
      var session = typeof rawSession === 'string' ? JSON.parse(rawSession) : {};
      token = session && session.authenticated ? session.authenticated.token : null;
    } catch (e) {
      token = null;
    }

    return token;
  }

  exports.default = getToken;
});