define('ui/controllers/diagnostics/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    feedbackUpdate: null,
    actions: {
      saveDiagnostic: function saveDiagnostic(model) {
        this.set('feedbackUpdate', null);
        var self = this;
        this.store.findRecord('diagnostic', model.id).then(function (diagnostic) {
          diagnostic.save().then(function () {
            // self.transitionToRoute('diagnostics');
            self.set('feedbackUpdate', "Diagnostic is updated successfuly!");
          }).catch(function () {});
        });
      }
    }
  });
});