define('ui/components/app-layout', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user')
  });
});