define('ui/components/repeat-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    classNames: ['repeat-field'],
    attributeBindings: ['data-id', 'data-type', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message', 'data-repeat-count'],
    subFields: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var field = get(this, 'field');
      var fields = get(this, 'fields');

      var narrowedFields = fields.filterBy('repeat_id', field.id);
      set(this, 'subFields', narrowedFields);
    }
  });
});