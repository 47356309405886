define('ui/models/field', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = Ember.computed,
      get = Ember.get;
  exports.default = _emberData.default.Model.extend({
    name: _emberData.default.attr('string'),
    type: _emberData.default.attr('string'),
    label: _emberData.default.attr('string'),
    apearance: _emberData.default.attr('string'),
    required: _emberData.default.attr('string'),
    relevent: _emberData.default.attr('string'),
    hint: _emberData.default.attr('string'),
    constraint: _emberData.default.attr('string'),
    constraint_message: _emberData.default.attr('string'),
    calculation: _emberData.default.attr('string'),
    repeat_count: _emberData.default.attr('string'),
    group_id: _emberData.default.attr('string'),
    survey_id: _emberData.default.attr('string'),
    repeat_id: _emberData.default.attr('string'),
    read_only: _emberData.default.attr('boolean'),
    default: _emberData.default.attr('string'),
    timestamp_req: _emberData.default.attr('boolean'),
    fetch_data_from_riptide: _emberData.default.attr('boolean'),
    fetch_data_for_field_name: _emberData.default.attr('string'),
    riptide_api_prop_name: _emberData.default.attr('string'),
    fetch_unit_tag_from_field_name: _emberData.default.attr('string'),

    isParentField: computed('group_id', 'relevent', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isrelevent = get(this, 'relevent') === null;

      return isgroup && isrepeat && isrelevent;
    }),
    isGroup: computed('group_id', function () {
      var type = get(this, 'type');
      return type.includes('begin group');
    }),
    isRepeat: computed('repeat_id', function () {
      var type = get(this, 'type');
      return type.includes('begin repeat');
    }),
    isInteger: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('integer');
    }),
    isDecimal: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('decimal');
    }),
    isText: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('text');
    }),
    isImage: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('image');
    }),
    isNote: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('note');
    }),
    isSelect: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('select');
    }),
    isMultiSelect: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('select_multiple');
    }),
    isOneSelect: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('select_one');
    }),
    isGeo: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('geopoint');
    }),
    isAcknowledge: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('acknowledge');
    }),
    isUser: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('username');
    }),
    isCalculate: computed('type', function () {
      var type = get(this, 'type');
      return type.includes('calculate');
    }),
    isParentGroup: computed('isGroup', 'group_id', 'repeat_id', function () {
      var isgroupid = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isgroup = get(this, 'isGroup') === true;

      return isgroupid && isrepeat && isgroup;
    }),
    isParentRepeat: computed('isRepeat', 'group_id', 'repeat_id', function () {
      var isgroupid = get(this, 'group_id') === null;
      var isrepeatid = get(this, 'repeat_id') === null;
      var isrepeat = get(this, 'isRepeat') === true;

      return isgroupid && isrepeatid && isrepeat;
    }),
    isParentInteger: computed('isInteger', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isinteger = get(this, 'isInteger') === true;

      return isgroup && isrepeat && isinteger;
    }),
    isParentDecimal: computed('isDecimal', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isdecimal = get(this, 'isDecimal') === true;

      return isgroup && isrepeat && isdecimal;
    }),
    isParentText: computed('isText', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var istext = get(this, 'isText') === true;

      return isgroup && isrepeat && istext;
    }),
    isParentImage: computed('isImage', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isimage = get(this, 'isImage') === true;

      return isgroup && isrepeat && isimage;
    }),
    isParentNote: computed('isNote', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isnote = get(this, 'isNote') === true;

      return isgroup && isrepeat && isnote;
    }),
    isParentSelect: computed('isSelect', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isselect = get(this, 'isSelect') === true;

      return isgroup && isrepeat && isselect;
    }),
    isParentGeo: computed('isGeo', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isgeo = get(this, 'isGeo') === true;

      return isgroup && isrepeat && isgeo;
    }),
    isParentAcknowledge: computed('isAcknowledge', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isack = get(this, 'isAcknowledge') === true;

      return isgroup && isrepeat && isack;
    }),
    isParentUser: computed('isUser', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var isuser = get(this, 'isUser') === true;

      return isgroup && isrepeat && isuser;
    }),
    isParentCalculate: computed('isCalculate', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') === null;
      var iscalc = get(this, 'isCalculate') === true;

      return isgroup && isrepeat && iscalc;
    }),

    // depend_field: computed('relevent', function(){
    //   if (get(this, 'relevent') && get(this, 'relevent').indexOf('$') >= 0) {
    //     var relevent = get(this, 'relevent')
    //     let first = relevent.indexOf('{')
    //     let firstSub = relevent.substr(0, first + 1)
    //     relevent = relevent.replace(firstSub, '')
    //     let last = relevent.indexOf('}')
    //     let lastSub = relevent.substr(last)
    //     var final = relevent.replace(lastSub, '')
    //
    //     return final
    //   }
    // }),
    // depend_value: computed('relevent', function(){
    //   if (get(this, 'relevent') && get(this, 'relevent').indexOf('$') >= 0) {
    //     var relevent = get(this, 'relevent')
    //     let first = relevent.indexOf('}')
    //     let firstSub = relevent.substr(first)
    //     let second = firstSub.indexOf("'") + 1
    //     let secondSub = firstSub.substr(second)
    //     let third = secondSub.indexOf("'")
    //     let thirdSub = secondSub.substr(third)
    //     let final = secondSub.replace(thirdSub, '')
    //
    //     return final
    //   }
    // }),


    isRepeatText: computed('isText', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var istext = get(this, 'isText') === true;

      return isgroup && isrepeat && istext;
    }),
    isRepeatInteger: computed('isInteger', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var isint = get(this, 'isInteger') === true;

      return isgroup && isrepeat && isint;
    }),
    isRepeatDecimal: computed('isDecimal', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var isdecimal = get(this, 'isDecimal') === true;

      return isgroup && isrepeat && isdecimal;
    }),
    isRepeatNote: computed('isNote', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var isnote = get(this, 'isNote') === true;

      return isgroup && isrepeat && isnote;
    }),
    isRepeatSelect: computed('isSelect', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var isSelect = get(this, 'isSelect') === true;
      return isgroup && isrepeat && isSelect;
    }),
    isRepeatImage: computed('isImage', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var isImage = get(this, 'isImage') === true;
      return isgroup && isrepeat && isImage;
    }),
    isRepeatGroup: computed('isGroup', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var isGroup = get(this, 'isGroup') === true;
      return isgroup && isrepeat && isGroup;
    }),
    isRepeatCalculate: computed('isCalculate', 'group_id', 'repeat_id', function () {
      var isgroup = get(this, 'group_id') === null;
      var isrepeat = get(this, 'repeat_id') !== null;
      var isCalc = get(this, 'isCalculate') === true;
      return isgroup && isrepeat && isCalc;
    }),

    hasRegex: computed('constraint', function () {
      var str = get(this, 'constraint') !== null;
      return str;
    }),
    regex: computed('constraint', function () {
      var str = get(this, 'constraint');
      var regex = str.match(/,'(.*)'\)/)[1];
      return regex;
    }),
    isRequired: computed('required', function () {
      var str = get(this, 'required').toLowerCase();
      return str.includes('true');
    })

  });
});