define('ui/components/decimal-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get;

  var $ = Ember.$;

  exports.default = Component.extend({
    classNames: ['decimal-field', 'field'],
    attributeBindings: ['data-id', 'data-type', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      var field_id = get(this, 'field.id');
      var field = $('.decimal-field[data-id=' + field_id + ']');
      field.click(function () {
        var submission_id = $('.survey-fields').attr('data-submission-id');
        $(this).find('input.submission-id').val(submission_id);
      });

      field.find('input[type=number]').focusout(function () {
        var required = field.attr('data-required').includes('true');

        if (!$(this).val() && required) {
          field.find('.error').text('This is a required field.');
        } else {
          field.find('.error').text('');
        }

        var val = $(this).val();
        $(this).val("");
        $(this).val(parseFloat(val).toFixed(2));
      });
    }
  });
});