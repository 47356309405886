define('ui/routes/diagnostics/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model(params) {
      return this.store.findRecord('diagnostic', params.diagnostic_id);
    },

    actions: {
      deleteDiagnostic: function deleteDiagnostic(diagnostic) {
        // let thing = this.modelFor(this.routeName);
        var self = this;
        diagnostic.destroyRecord().then(function () {
          self.transitionTo('diagnostics');
        }).catch(function () {});
      }
    }
  });
});