define('ui/controllers/diagnostics/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      saveDiagnostic: function saveDiagnostic() {
        var diagnostic = this.store.createRecord('diagnostic', {
          name: this.get('model.name'),
          message: this.get('model.message')
        });

        var self = this;
        diagnostic.save().then(function () {
          self.transitionToRoute('diagnostics');
          document.location.reload();
        }).catch(function () {});
      }
    }
  });
});