define('ui/services/current-user', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var service = Ember.inject.service,
      RSVP = Ember.RSVP;
  exports.default = Ember.Service.extend({
    session: service('session'),
    store: service(),

    load: function load() {
      var _this = this;

      if (this.get('session.isAuthenticated')) {
        return this.get('store').queryRecord('user', { me: true }).then(function (user) {
          _this.set('user', user);
        });
      } else {
        return RSVP.resolve();
      }
    }
  });
});