define('ui/controllers/companies/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    filteredUsers: computed('model.company', 'model.company.users', function () {
      var assignedUserIds = this.get('model.company.users').map(function (user) {
        return user.id;
      });

      var filteredUsers = this.get('model.allUsers').filter(function (user) {
        var _iteratorNormalCompletion = true;
        var _didIteratorError = false;
        var _iteratorError = undefined;

        try {
          for (var _iterator = assignedUserIds[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
            var userId = _step.value;

            if (user.id == userId) return false;
          }
        } catch (err) {
          _didIteratorError = true;
          _iteratorError = err;
        } finally {
          try {
            if (!_iteratorNormalCompletion && _iterator.return) {
              _iterator.return();
            }
          } finally {
            if (_didIteratorError) {
              throw _iteratorError;
            }
          }
        }

        return true;
      }).sort(function (a, b) {
        return a.get('id') - b.get('id');
      });

      return filteredUsers;
    }),

    actions: {
      refreshRoute: function refreshRoute() {
        this.send('refreshCurrentRoute');
      }
    }
  });
});