define('ui/components/submission-show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var Component = Ember.Component;
  var get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    repeat_count: null,
    field_names: null,

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
      var model = get(this, 'model');
      var answers = model.answers;
      // console.log(answers)
      var rns = answers.mapBy('repeat_number');
      var rns2 = rns.map(function (a) {
        return parseInt(a, 10);
      });
      var rc = Math.max.apply(Math, _toConsumableArray(rns2));
      var rca = [].concat(_toConsumableArray(Array(rc).keys()));
      rca = rca.map(function (num) {
        return num + 1;
      });
      rca.unshift(0);
      // console.log(rca)
      set(this, 'repeat_count', rca);

      var ans = answers.mapBy('field_name');
      var ans2 = ans.map(function (a) {
        return a;
      });
      ans2 = ans2.uniq();
      set(this, 'field_names', ans2);
    }
  });
});