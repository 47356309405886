define('ui/models/user', ['exports', 'ember-data'], function (exports, _emberData) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Model.extend({
    email: _emberData.default.attr('string'),
    first_name: _emberData.default.attr('string'),
    last_name: _emberData.default.attr('string'),
    password: _emberData.default.attr('string'),
    role: _emberData.default.attr('string'),
    vendor: _emberData.default.attr('string'),
    active: _emberData.default.attr('boolean', { defaultValue: true }),
    companies: _emberData.default.hasMany('company'),
    surveys: _emberData.default.hasMany('survey')
  });
});