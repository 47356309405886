define('ui/components/field-value-by-name', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  var get = Ember.get,
      set = Ember.set;
  exports.default = Component.extend({
    // n: 0,
    newN: null,
    answer: null,

    didReceiveAttrs: function didReceiveAttrs() {

      var n = get(this, 'n');
      // console.log(n)
      // n = n + 1
      set(this, "newN", n + "");

      var answers = get(this, 'answers');
      var field_name = get(this, 'field_name');
      // console.log(answers)
      var ans_by_repeat = answers.filterBy('repeat_number', n + "");
      var ans = ans_by_repeat.findBy('field_name', field_name);
      // console.log(ans)
      set(this, "answer", ans);
    }
  });
});