define('ui/components/rule-form-two', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({

    actions: {
      saveRule: function saveRule() {
        this.attrs.heyThere();
      }
    }
  });
});