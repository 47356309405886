define('ui/authorizers/custom', ['exports', 'ember-simple-auth/authorizers/base'], function (exports, _base) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    session: Ember.inject.service(),
    authorize: function authorize(data, block) {
      if (Ember.testing) {
        block('Authorization', 'beyonce');
      }
      var token = data.token;

      if (this.get('session.isAuthenticated') && token) {
        block('Authorization', '' + token);
      }
    }
  });
});