define('ui/routes/surveys/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      return this.store.createRecord('survey');
    },

    actions: {
      saveSurvey: function saveSurvey() {
        var thing = this.modelFor(this.routeName);
        var self = this;
        thing.save().then(function () {
          self.transitionTo('suvrveys');
        }).catch(function () {});
      }
    }
  });
});