define('ui/components/survey-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['survey-field'],
    attributeBindings: ['data-id', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message']

    // didReceiveAttrs(){
    //   this._super(...arguments);
    //   let field = get(this, 'field');
    //   let fields = get(this, 'fields');
    //
    //   let narrowedFields = fields.filterBy('isParentField', false)
    //   set(this, 'subFields', narrowedFields);
    // }

  });
});