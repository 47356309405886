define('ui/controllers/password', ['exports', 'ui/config/environment'], function (exports, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      updateNewPassword: function updateNewPassword() {
        var newPassword = Ember.$('#updateNewPassword #new_password').val();
        var newPasswordConfirmation = Ember.$('#updateNewPassword #new_password_confirmation').val();
        var token = this.model.token;

        if (newPassword == '') {
          Ember.$('#updateNewPassword').removeClass('d-none');
          Ember.$('#updateNewPasswordResponse .alert').removeClass('alert-success d-none').addClass('alert-danger').text("password can not be blank");
        } else if (newPassword === newPasswordConfirmation) {
          Ember.$.ajax({
            url: _environment.default.host + '/api/v1/password/' + token + '/edit',
            type: 'PUT',
            data: { "password": newPassword }
          }).then(function (data) {
            Ember.$('#updateNewPassword').addClass('d-none');
            Ember.$('#updateNewPasswordResponse .alert').removeClass('alert-danger d-none').addClass('alert-success').text(data.success);
          }).catch(function (data) {
            var errors = data.responseJSON.errors;
            var errorString = errors ? Object.keys(errors).map(function (key) {
              return key + ' ' + errors[key];
            }).join('\n') : data.responseJSON.error;

            Ember.$('#updateNewPassword').removeClass('d-none');
            Ember.$('#updateNewPasswordResponse .alert').removeClass('alert-success d-none').addClass('alert-danger').text(errorString);
          });
        } else {
          Ember.$('#updateNewPassword').removeClass('d-none');
          Ember.$('#updateNewPasswordResponse .alert').removeClass('alert-success d-none').addClass('alert-danger').text("password and password confirmation does not match.");
        }
      }
    }
  });
});