define('ui/components/report-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      changeSearchText: function changeSearchText() {
        var searchText = Ember.$('#searchText').val();
        this.sendAction('updateSearchText', searchText);
      }
    }
  });
});