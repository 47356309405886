define('ui/components/report-companies', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      changeCompany: function changeCompany(company) {
        this.sendAction('updateSelectedCompany', company.id);
      },
      changeSearchCompanyText: function changeSearchCompanyText() {
        var searchCompany = Ember.$('#searchCompany').val();
        this.sendAction('updateSearchCompanyText', searchCompany);
      }
    }
  });
});