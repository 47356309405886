define('ui/components/report-radios', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    actions: {
      changeSearchBy: function changeSearchBy(type) {
        this.sendAction('updateSearchBy', type);
      }
    }
  });
});