define('ui/components/assign-rule-form', ['exports', 'ui/config/environment', 'ui/helpers/tokenParser'], function (exports, _environment, _tokenParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);
      Ember.$('#assign-rule').submit(function (e) {
        e.preventDefault();
        Ember.$('#assign-rule .alert').removeClass('alert-danger alert-success').addClass('alert-primary').text('Processing');
        Ember.$('#assign-rule .submit').attr("disabled", "disabled");

        Ember.$.ajax({
          url: '' + _environment.default.host + _environment.default.assign_rule,
          headers: {
            'Authorization': (0, _tokenParser.default)()
          },
          type: 'POST',
          data: new FormData(this),
          processData: false,
          contentType: false
        }).done(function (data) {
          if (data.error) {
            Ember.$('#assign-rule .alert').removeClass('alert-primary alert-success').addClass('alert-danger').text(data.error);
            Ember.$('#assign-rule .submit').removeAttr("disabled");
          } else {
            Ember.$('#assign-rule .alert').removeClass('alert-primary alert-danger').addClass('alert-success').text(data.success);
            Ember.$('#assign-rule .submit').removeAttr("disabled");

            document.location.reload();
          }
        }).fail(function (error) {
          Ember.$('#assign-rule .alert').removeClass('alert-primary alert-success').addClass('alert-danger').text(error.statusText);
          Ember.$('#assign-rule .submit').removeAttr("disabled");
        });
      });
    }
  });
});