define('ui/routes/surveys/show/submissions', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    model: function model() {
      var _paramsFor = this.paramsFor('surveys.show'),
          survey_id = _paramsFor.survey_id;

      return this.store.query('submission', { survey_id: survey_id });
    }
  });
});