define('ui/components/user-form', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    // store: Ember.inject.service(),
    didRender: function didRender() {
      this._super.apply(this, arguments);

      Ember.$('.password').focus(function () {
        Ember.$(this).next().text('Password should be minimum 8 characters long.');
      });
    },

    actions: {
      saveUser: function saveUser() {
        this.attrs.heyThere();
      }
    }
  });
});