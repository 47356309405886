define("ui/transforms/object", ["exports", "ember-data"], function (exports, _emberData) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberData.default.Transform.extend({
    deserialize: function deserialize(serialized) {
      // return serialized;
      return Ember.isBlank(serialized) ? {} : serialized;
    },
    serialize: function serialize(deserialized) {
      // return deserialized;
      return Ember.isBlank(deserialized) ? {} : deserialized;
    }
  });
});