define('ui/components/test-images-limit', ['exports', 'ui/config/environment', 'ui/helpers/tokenParser'], function (exports, _environment, _tokenParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;

  var $ = Ember.$;

  exports.default = Component.extend({
    didRender: function didRender() {
      this._super.apply(this, arguments);

      // add file
      $('.add-file').on('click', function () {
        var first = $('.upload-file:first');
        first.clone(true).appendTo('.files');

        $('.upload-file:last .alert').text('');
        $('.upload-file:last .preview img').attr('src', '');
      });

      $('.upload-file input').on('change', function () {
        var parent = $(this).closest('form.upload-file');
        var form = $(this).closest('form.upload-file')[0];
        var formData = new FormData(form);
        var alrt = $(this).siblings('.alert');

        alrt.removeClass('alert-danger alert-success').addClass('alert-primary').text('Processing');
        $.ajax({
          url: '' + _environment.default.host + _environment.default.test_images,
          headers: {
            'Authorization': (0, _tokenParser.default)()
          },
          type: 'POST',
          data: formData,
          processData: false,
          contentType: false
        }).done(function (data) {
          if (data.error) {
            alrt.removeClass('alert-primary').removeClass('alert-success').addClass('alert-danger').text(data.error);
          } else {
            alrt.removeClass('alert-primary').removeClass('alert-danger').addClass('alert-success').text(data.success);
            parent.find('.preview img').attr('src', data.url);
          }
        }).fail(function () {
          alrt.removeClass('alert-primary').removeClass('alert-success').addClass('alert-danger').text("Upload failed");
        });
        form.reset();
      });
    }
  });
});