define('ui/routes/companies/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    model: function model(params) {
      return RSVP.hash({
        company: this.store.findRecord('company', params.company_id),
        allUsers: this.get('store').findAll('user')
      });
    },


    actions: {
      deleteCompany: function deleteCompany(company) {
        // let thing = this.modelFor(this.routeName);
        var self = this;
        company.destroyRecord().then(function () {
          self.transitionTo('companies');
        }).catch(function () {});
      },
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      }
    }
  });
});