define('ui/controllers/application', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user')

    // actions: {
    //   invalidateSession() {
    //     this.get('session').invalidate();
    //   }
    // }

  });
});