define('ui/routes/not-found', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  exports.default = Route.extend({
    redirect: function redirect() {
      var url = this.router.location.formatURL('/not-found');
      if (window.location.pathname !== url) {
        this.transitionTo('/not-found');
      }
    }
  });
});