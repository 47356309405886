define('ui/controllers/surveys/edit', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  var computed = Ember.computed;
  exports.default = Controller.extend({
    do_send: "true",
    send_reports: ["true", "false"],

    companyId: computed(function () {
      var survey = this.get('model.survey.data');
      return survey.company_id;
    }),

    actions: {
      saveSurvey: function saveSurvey(survey) {
        var self = this;
        this.get('store').findRecord('survey', survey.id).then(function (survey) {
          survey.set('company_id', self.get('companyId'));
          survey.set('send_reports', self.get('do_send'));
          survey.save().then(function () {
            self.transitionToRoute('surveys');
          }).catch(function () {});
        });
      },
      selectCompany: function selectCompany(company) {
        this.set('companyId', company);
      },
      sendReportsYn: function sendReportsYn(do_send) {
        this.set('do_send', do_send);
      }
    }
  });
});