define('ui/router', ['exports', 'ui/config/environment', 'ui/helpers/tokenParser'], function (exports, _environment, _tokenParser) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var EmberRouter = Ember.Router;


  var Router = EmberRouter.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });

  Router.map(function () {
    if ((0, _tokenParser.default)()) {
      this.route('users', function () {
        this.route('show', { path: '/:user_id' });
        this.route('edit', { path: '/:user_id/edit' });
        this.route('new');
      });
      this.route('companies', function () {
        this.route('show', { path: '/:company_id' });
        this.route('new');
        this.route('edit', { path: '/:company_id/edit' });
      });
      this.route('diagnostics', function () {
        this.route('show', { path: '/:diagnostic_id' }, function () {
          this.route('rules');
        });
        this.route('new');
        this.route('edit', { path: '/:diagnostic_id/edit' });
      });
      this.route('surveys', function () {
        this.route('new');
        this.route('show', { path: '/:survey_id' }, function () {
          // this.route('fields');
          this.route('submissions');
          this.route('diagnostics');
        });
        this.route('edit', { path: '/:survey_id/edit' });
      });
      this.route('reports');
      this.route('profile');
      this.route('dashboard');
      this.route('upload_form');
      this.route('fields', function () {
        this.route('show', { path: '/:field_id' }, function () {
          // this.route('rules');
        });
        this.route('edit', { path: '/:field_id/edit' });
      });
      this.route('submit_form', { path: '/x/:survey_id/:company_id/:user_id' });
      this.route('mobile_form', { path: '/cshgaqsdoiuyghjdg33kgh223uy/:survey_id/:company_id/:user_id' });

      this.route('submission', function () {
        this.route('show', { path: '/:submission_id' });
      });
      this.route('rules', function () {
        this.route('show', { path: '/:rule_id' });
        this.route('new');
        this.route('edit', { path: '/:rule_id/edit' });
      });

      this.route('diagnostic', function () {
        this.route('show', function () {});
      });
      this.route('test-images');
    } else {
      this.route('login');
      this.route('reset-password');
      this.route('password', { path: '/users/password/:token/edit' });
    }
    this.route('not-found', { path: '/*path' });
    this.route('version');
  });

  exports.default = Router;
});