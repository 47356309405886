define('ui/controllers/companies/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      saveCompany: function saveCompany() {
        var company = this.store.createRecord('company', {
          name: this.get('model.name'),
          riptide_api_company_name: this.get('model.riptide_api_company_name')
        });

        var self = this;
        company.save().then(function () {
          self.transitionToRoute('companies');
          document.location.reload();
        }).catch(function () {});
      }
    }
  });
});