define('ui/components/image-field', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = Ember.Component;
  exports.default = Component.extend({
    classNames: ['image-field', 'field'],
    attributeBindings: ['data-id', 'data-type', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message'],

    didReceiveAttrs: function didReceiveAttrs() {
      this._super.apply(this, arguments);
    },
    didInsertElement: function didInsertElement() {
      this._super.apply(this, arguments);
    },
    didRender: function didRender() {
      this._super.apply(this, arguments);
      // let field_id = get(this, 'field.id')
      // let field = $('.image-field[data-id=' + field_id + ']')
      // let repeat_number = field.find('.repeat_number_input').val()
      // let submission_id = field.find('.submission-id')
      // cl(submission_id.val())
      // if (!submission_id.val()){
      //   Ember.run.later(function(){
      //     let sub = $('.survey-fields').attr('data-submission-id');
      //     field.find('.submission-id').val(sub)
      //
      //     $.ajax({
      //       url: `${config.host}${config.draft_image_url}/${sub}/${field_id}/${repeat_number}`
      //     })
      //     .done(function(data){
      //       if (data.error){
      //         data.error
      //       } else {
      //         field.find('.image-preview img').attr('src', data.url)
      //       }
      //     })
      //     .fail(function(){
      //
      //     })
      //
      //   }, 1000)
      // }


      // field.click(function(){
      //   let submission_id = $('.survey-fields').attr('data-submission-id')
      //   $(this).find('input.submission-id').val(submission_id)
      // })

      // field.find('input[type=file]').focusout(function(){
      //   let required = field.attr('data-required').includes('true')
      //
      //   if(!$(this).val() && required){
      //     field.find('.error').text('This is a required field.')
      //   }else{
      //     field.find('.error').text('')
      //   }
      // });


      // field.find('input[type=file]').on('change', function(){
      //   field.find('.image-preview img').attr('src', '')
      //   let form = $(this).closest('form.upload-file')[0]
      //   let formData = new FormData(form)
      //
      //   let alrt = $(this).siblings('.alert')
      //   alrt.removeClass('alert-danger').removeClass('alert-success').addClass('alert-primary').text('Processing');
      //   $.ajax({
      //     url: `${config.host}${config.draft_answer}`,
      //     type: 'POST',
      //     data: formData,
      //     processData: false,
      //     contentType: false,
      //   })
      //   .done(function(data){
      //     if (data.error){
      //       alrt.removeClass('alert-primary').removeClass('alert-success').addClass('alert-danger').text(data.error);
      //     } else {
      //       field.find('.image-preview img').attr('src', data.url)
      //       alrt.removeClass('alert-primary').removeClass('alert-danger').addClass('alert-success').text(data.success);
      //     }
      //   })
      //   .fail(function(){
      //     alrt.removeClass('alert-primary').removeClass('alert-success').addClass('alert-danger').text("Upload failed");
      //   })
      //   form.reset()
      // })

    }
  });
});