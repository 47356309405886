define('ui/components/submission-field-value2', ['exports'], function (exports) {
	'use strict';

	Object.defineProperty(exports, "__esModule", {
		value: true
	});
	var Component = Ember.Component;
	var get = Ember.get,
	    set = Ember.set;
	exports.default = Component.extend({
		n: 0,
		newN: null,
		answer: null,

		didReceiveAttrs: function didReceiveAttrs() {

			var n = get(this, 'n');
			n = n + 1;
			set(this, "newN", n + "");

			// console.log(answers)
			// let ans = answers.findBy('field_name', field_name)
			// console.log(ans)
		}
	});
});