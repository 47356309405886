define('ui/components/username-field', ['exports'], function (exports) {
                    'use strict';

                    Object.defineProperty(exports, "__esModule", {
                                        value: true
                    });
                    var Component = Ember.Component;
                    exports.default = Component.extend({
                                        classNames: ['username-field', 'field'],
                                        attributeBindings: ['data-id', 'data-type', 'data-repeat-id', 'data-group-id', 'data-name', 'data-required', 'data-relevent', 'data-constraint', 'data-constraint-message']
                    });
});