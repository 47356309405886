define('ui/controllers/rules/new', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    actions: {
      saveRule: function saveRule() {
        var rule = this.store.createRecord('rule', {
          name: this.get('model.name'),
          content: this.get('model.content')
        });

        var self = this;
        rule.save().then(function () {
          self.transitionToRoute('rules');
          document.location.reload();
        }).catch(function () {});
      }
    }
  });
});