define('ui/routes/users/show', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = Ember.Route;
  var RSVP = Ember.RSVP;
  exports.default = Route.extend({
    assignForm: "companies",
    searchSurveyText: "",

    model: function model(params) {
      var user = this.store.findRecord('user', params.user_id);
      var allCompanies = [];
      var allSurveys = [];
      var showCompanies = false;
      var showSurveys = false;

      if (this.assignForm === "companies") {
        showCompanies = true;
        showSurveys = false;
        allCompanies = this.get('store').findAll('company');
      } else if (this.assignForm === "surveys") {
        showCompanies = false;
        showSurveys = true;
        allSurveys = this.get('store').findAll('survey');
      } else {
        showCompanies = false;
        showSurveys = false;
      }

      return RSVP.hash({
        user: user,
        allCompanies: allCompanies,
        allSurveys: allSurveys,
        assignForm: this.assignForm,
        showCompanies: showCompanies,
        showSurveys: showSurveys
      });
    },


    actions: {
      deleteUser: function deleteUser(user) {
        var self = this;
        user.destroyRecord().then(function () {
          self.transitionTo('users');
        }).catch(function () {});
      },
      refreshCurrentRoute: function refreshCurrentRoute() {
        this.refresh();
      },
      updateAssignForm: function updateAssignForm(assignForm) {
        this.set('assignForm', assignForm);
        this.refresh();
      },
      updateSearchSurveyText: function updateSearchSurveyText(searchSurveyText) {
        this.set('searchSurveyText', searchSurveyText);
        this.refresh();
      }
    }
  });
});