define('ui/controllers/index', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = Ember.Controller;
  exports.default = Controller.extend({
    session: Ember.inject.service('session'),
    currentUser: Ember.inject.service('current-user'),

    actions: {
      authenticate: function authenticate() {
        var _this = this;

        var credentials = this.getProperties('identification', 'password'),
            authenticator = 'authenticator:jwt';

        this.get('session').authenticate(authenticator, credentials).then(function () {
          Ember.$(location).attr('href', 'http://' + document.location.host + '/dashboard');
        }).catch(function (reason) {
          _this.set('errorMessage', reason.error || reason.responseJSON.error || reason);

          Ember.$('#main-flash-message-section').removeClass('d-none');
          Ember.$('#main-flash-message-section .alert').removeClass('alert-success d-none').addClass('alert-danger').text(_this.errorMessage);
        });
      }
    }
  });
});